import React, { Component } from "react"
import ContextConsumer from "../services/context"
import { Link } from "gatsby"

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          slug
          content
          date
          excerpt
          status
          featured_media {
            source_url
          }
        }
      }
    }
  }
`

let postsToShow = 12;

class News extends Component {
  state = {
    showingMore: postsToShow > 20,
    postsToShow,
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (distanceToBottom < 100) {
      this.setState({ postsToShow: this.state.postsToShow + 20 })
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }


  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.postsToShow = this.state.postsToShow
  }

  postTitle(title) {
    return <span dangerouslySetInnerHTML={{ __html: title}} />
  }
  postExcerpt(excerpt) {
    return <div dangerouslySetInnerHTML={{ __html: excerpt }} />
  }

  render() {
    const posts = this.props.data.allWordpressPost.edges.map(e => e.node)
    let postsSlice = posts.slice(0, this.state.postsToShow)

    return (
      <div className="mx-6 mt-10 md:mt-6 lg:mt-6 xl:mt-6">
        <div className="w-full border border-gray-400 p-4">
          <div className="text-4xl uppercase">News</div>
        </div>
        <div>
          {postsSlice.map( (post, index ) => {
            const title = this.postTitle(post.title)
            const excerpt = this.postExcerpt(post.excerpt)
            let featuredImage = ""
            let firstPostCx

            if(post.featured_media) {
              featuredImage = <img className="w-full" src={post.featured_media.source_url} />
            }

            if ( index === 0) {
              firstPostCx = "border-t-0"
            }

            if ( post.status === "publish" ) {
              return(
                <div className="flex mb-6">
                  <Link to={`/${post.slug}`} className={`w-3/12 md:w-4/12 lg:w-4/12 xl:w-4/12 p-2 md:p-4 lg:p-4 xl:p-4 h-0-pc border border-r-0 xl:border-r-0 border-gray-400 hover:bg-gray-200 cursor-pointer ${firstPostCx}`} style={{boxShadow: '1px 0 0 0 #C2C2C2'}}>
                    {featuredImage}
                  </Link>
                  <div className="w-9/12 md:w-7/12 lg:w-7/12 xl:w-7/12">
                    <Link to={`/${post.slug}`} className={`text-2xl md:text-4xl lg:text-4xl xl:text-4xl uppercase p-4 border border-gray-400 hover:bg-k7green block cursor pointer ${firstPostCx}`}>{title}</Link>
                    <div className="hidden md:block lg:block xl:block w-2/3 p-6 border border-gray-400 border-t-0 text-sm">{excerpt}</div>
                    <Link to={`/${post.slug}`} className="self-end text-sm w-32 block text-center border-t-0 mono uppercase p-2 md:p-4 lg:p-4 xl:p-4 border border-gray-400 bg-k7green2 hover:bg-k7green cursor-pointer">Read More</Link>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>

    )

  }

}
News.contextType = ContextConsumer;
export default News
